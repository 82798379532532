import { useMemo, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HomePageEventsQuery } from '@/apollo/operations';
import { PlayerShareCard, PlayerShareCardProps } from '@/components/cards';
import { PlayerShareCardsList } from '@/components/styled/cards';
import { useTournamentsPlayerShareCards } from '@/hooks';
import { styled, useTheme } from '@mui/material/styles';
import { Text, Text as DefText } from '../texts';

export type EventProps = {
  event: Event;
  slider?: boolean;
} & Pick<PlayerShareCardProps, 'withVenueLink' | 'withTournamentLink'> &
  ComponentProps<typeof Wrapper>;

const PublicEventPlayerShares = ({
  event,
  withVenueLink,
  withTournamentLink,
  slider = false,
  ...props
}: EventProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { shares } = useTournamentsPlayerShareCards(
    event.location,
    event.tournaments
  );

  const content = useMemo(() => {
    if (slider) {
      return (
        <Swiper
          style={{
            padding: `30px 60px 50px`,
            margin: `-30px -60px -50px`,
          }}
          spaceBetween={16}
          slidesPerView={1.5}
          breakpoints={{
            [theme.breakpoints.values.sm]: {
              slidesPerView: 3.2,
              // spaceBetween: 30,
            },
            // when window width is >= md
            [theme.breakpoints.values.md]: {
              slidesPerView: 3.5,
              spaceBetween: 32,
            },
            [theme.breakpoints.values.lg]: {
              slidesPerView: 4.5,
              spaceBetween: 32,
            },
          }}
        >
          {shares.map((share) => (
            <SwiperSlide key={share.id}>
              <PlayerShareCard
                data={share}
                withVenueLink={withVenueLink}
                withTournamentLink={withTournamentLink}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      );
    } else {
      const limitExceed = shares.length > maxSharesAmount;
      return (
        <>
          <TournamentPlayerSharesList>
            {shares.slice(0, maxSharesAmount).map((share) => (
              <li key={share.id}>
                <PlayerShareCard
                  data={share}
                  withVenueLink={withVenueLink}
                  withTournamentLink={withTournamentLink}
                />
              </li>
            ))}
          </TournamentPlayerSharesList>
          {limitExceed && (
            <TournamentLimitText variant={'title-large'}>
              <Link href={`/event/${event.slug}`}>
                {t('PUBLIC_EVENT_PLAYER_SHARES__moreEventShares')} {event.name}
              </Link>
            </TournamentLimitText>
          )}
        </>
      );
    }
  }, [
    t,
    theme,
    slider,
    withVenueLink,
    withTournamentLink,
    event.name,
    event.slug,
    shares,
  ]);

  return (
    <Wrapper {...props}>
      <TitleWrapper>
        <Title component={'h2'} variant={'display-small'}>
          <Link href={`/event/${event.slug}`}>
            {!!event.image && (
              <Image
                src={event.image}
                alt={event.name}
                width={40}
                height={40}
              />
            )}
            {event.name}
          </Link>
        </Title>
      </TitleWrapper>
      {content}
    </Wrapper>
  );
};

type Event = HomePageEventsQuery['events'][number];

const maxSharesAmount = 8;

const Wrapper = styled('section')``;

const TitleWrapper = styled('div')`
  img {
    object-fit: contain;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
`;

const Title = styled(Text)`
  margin-bottom: 26px;
`;

const TournamentPlayerSharesList = styled(PlayerShareCardsList)``;

const TournamentLimitText = styled(DefText)`
  margin-top: 56px;
`;

export { PublicEventPlayerShares };
