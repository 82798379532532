import { useMemo, ComponentProps, ReactNode } from 'react';
import { isFunction } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import DefImage from 'next/image';
import Link from 'next/link';
import { EventListItemDataFragment } from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { ItemStatus } from '@/components/styled';
import { TranslationKeys } from '@/utils/i18n';
import { Paper, ButtonBase, ButtonBaseProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Text as DefText, TextProps } from '../texts';

export type EventListItemProps = {
  event: Event;
  descriptions: ReadonlyArray<readonly [TranslationKeys, ReactNode]>;
  buttonsLoading?: boolean;
  onEditButtonClick?: () => void;
  onCancelButtonClick?: () => void;
} & (
  | ({ href: string } & ComponentProps<typeof ButtonWrapper>)
  | Omit<ComponentProps<typeof SimpleWrapper>, '$withButtons'>
);

const EventListItem = ({
  event,
  descriptions,
  onEditButtonClick,
  onCancelButtonClick,
  ...props
}: EventListItemProps) => {
  const { t } = useTranslation();

  const Wrapper = useMemo(
    () => ('href' in props ? ButtonWrapper : SimpleWrapper),
    [props]
  );

  const withControls = [onEditButtonClick, onCancelButtonClick].some(
    isFunction
  );

  return (
    <Wrapper $withButtons={withControls} {...props}>
      <ImageWrapper>
        {!!event.image && (
          <Image
            width={102}
            height={102}
            src={event.image}
            alt={event.name}
          ></Image>
        )}
      </ImageWrapper>
      <Content>
        <Name>{event.name}</Name>
        <Location>{event.location}</Location>
        {descriptions.map(([label, value]) => (
          <Text key={label}>
            {t(label)}: {value}
          </Text>
        ))}
      </Content>
      {withControls && (
        <Controls>
          <ItemStatus>{event.status}</ItemStatus>
          {isFunction(onEditButtonClick) && (
            <ControlButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onEditButtonClick();
              }}
            >
              {t('EVENT_LIST_ITEM__editButton')}
            </ControlButton>
          )}
          {isFunction(onCancelButtonClick) && (
            <ControlButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onCancelButtonClick();
              }}
            >
              {t('EVENT_LIST_ITEM__cancelButton')}
            </ControlButton>
          )}
        </Controls>
      )}
    </Wrapper>
  );
};

type Event = EventListItemDataFragment;

/*const SimpleWrapper = styled(Paper)`
  display: flex;
  padding: 16px;
  text-align: left;
  align-items: stretch;
`;*/

const SimpleWrapper = styled(Paper)<{
  $withButtons: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ $withButtons }) =>
    $withButtons ? '24.16% 1fr auto' : '24.16% 1fr'};
  grid-gap: 16px;
  padding: 16px;
  text-align: left;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 30% 1fr;
  }
`;

const ButtonWrapper = styled(SimpleWrapper)``;

ButtonWrapper.defaultProps = {
  // @ts-ignore
  component: (props: ButtonBaseProps) => (
    // @ts-ignore
    <ButtonBase component={Link} {...props} />
  ),
};

const ImageWrapper = styled('div')`
  aspect-ratio: 1;
  align-self: start;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
`;

const Image = styled(DefImage)`
  object-fit: contain;
  object-position: center;
  margin: 8px;
`;

const Content = styled('div')`
  flex: 1 1 auto;
`;

const Name = styled((props: TextProps) => (
  <DefText
    component={'h2'}
    variant={'headline-small'}
    weight={'semi-bold'}
    {...props}
  />
))``;

const Location = styled((props: TextProps) => (
  <DefText variant={'title-medium'} color={'primary'} {...props} />
))`
  margin-bottom: 16px;
`;

const Text = styled((props: TextProps) => (
  <DefText variant={'body-large'} {...props} />
))``;

const Controls = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  gap: 8px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-column: 1 / -1;
    flex-direction: row;
  }
`;

const ControlButton = styled(Button)`
  min-width: 112px;
`;

export { EventListItem };
