import { useMemo, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import Link from 'next/link';
import {
  CommonEventDataFragment,
  TournamentPlayerShareCardsDataFragment,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { InfoBlock, ShareProgress } from '@/components/common-elements';
import { useOpenBuyPlayerShareModal, useOnPlayerPress } from '@/hooks';
import { resetListStyles } from '@/styles';
import dayjs from '@/utils/dayjs';
import { getPrice } from '@/utils/helpers';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';

export type PlayerShareCardProps = {
  data: Data;
  withVenueLink?: boolean;
  withEventLink?: boolean;
  withTournamentLink?: boolean;
} & Omit<ComponentProps<typeof Wrapper>, 'href'>;

const PlayerShareCard = ({
  data,
  withVenueLink = true,
  withTournamentLink = true,
  ...props
}: PlayerShareCardProps) => {
  const { t } = useTranslation();
  const openBuyPlayerShareModal = useOpenBuyPlayerShareModal();
  const onPlayerPress = useOnPlayerPress();
  /*
  * JW's comment:
  * We have this data:
   tournament.buyIn
   playerShare.shareToSell
   playerShare.markUp
   playerSharesBought.totalSharePercentage
   playerSharesBought.totalShareAmount
   playerSharesBought.totalTotalAmount
   — calculations:
   Sold where 60% => playerShare.shareToSell
   Sold where 20% => playerSharesBought.totalSharePercentage
   Sold where $900 = playerShare.shareToSell * (tournament.buyIn / 100) * playerShare.markUp
   Sold where $300 = Sold where $900  - playerSharesBought.totalTotalAmount
   Available = (Sold where $900) - (Sold where $300) */

  const {
    avatar,
    playerName,
    infoBlocks,
    tournamentName,
    location,
    date,
    currentAmount,
    totalAmount,
    progressPercent,
    totalPercent,
  } = useMemo(() => {
    const totalAmount =
      data.sharesToSell * (data.buyIn / 100) * data.markUp * data.bullets;
    const currentAmount = data.player_shares_bought?.totalTotalAmount ?? 0;

    return {
      avatar: data.player.image,
      playerName: data.player.name,
      infoBlocks: [
        {
          title: t('EVENTS__playerBuyIn'),
          value: getPrice(data.buyIn, 0),
        },
        {
          title: t('EVENTS__playerMarkup'),
          value: data.markUp,
        },
        {
          title: t('EVENTS__playerAvailable'),
          value: getPrice(totalAmount - currentAmount, 0),
        },
        {
          title: t('EVENTS__playerBullets'),
          value: data.bullets,
          share: data.shareDilution ?? false,
        },
      ],
      tournamentName: data.name,
      location: data.location,
      date: dayjs(data.date).format('MMMM D, YYYY - h:mmA'),
      currentAmount,
      totalAmount,
      progressPercent: data.player_shares_bought?.totalSharePercentage ?? 0,
      totalPercent: data.sharesToSell,
    };
  }, [t, data]);

  const contentName = useMemo(
    () =>
      withTournamentLink ? (
        <Link href={`/tournament/${data.tournamentSlug}`}>
          {tournamentName}
        </Link>
      ) : (
        tournamentName
      ),
    [withTournamentLink, data.tournamentSlug, tournamentName]
  );

  const locationValue = useMemo(
    () =>
      withVenueLink && data.venueSlug ? (
        <Link href={`/venue/${data.venueSlug}`}>{location}</Link>
      ) : (
        location
      ),
    [withVenueLink, data.venueSlug, location]
  );

  return (
    <Wrapper {...props}>
      <Avatar
        href={{
          pathname: '/player/[slug]',
          query: { slug: data.player.slug },
        }}
        onClick={(event) => {
          onPlayerPress(data.player.id, event);
        }}
      >
        {!!avatar && <AvatarImg src={avatar} alt={'Player avatar'} fill />}
        <AvatarName component={'h3'} variant={'title-large'}>
          {playerName}
        </AvatarName>
      </Avatar>
      <Content>
        <ContentName
          component={'h3'}
          variant={'title-medium'}
          weight={'semi-bold'}
        >
          {contentName}
        </ContentName>
        <ContentLocation variant={'title-small'} color={'primary'}>
          {locationValue}
        </ContentLocation>
        <ContentDate variant={'body-small'} color={'spunPearl'}>
          {date}
        </ContentDate>
        <InfoBlocksList>
          {infoBlocks.map((props) => (
            <InfoBlock key={props.title} {...props} />
          ))}
        </InfoBlocksList>
        <ContentProgress
          currentAmount={currentAmount}
          totalAmount={totalAmount}
          currentPercent={progressPercent}
          totalPercent={totalPercent}
        />

        <ShareButtonWrapper>
          <div>
            <Button
              variant={'outlined'}
              size={'small'}
              disabled={data.status !== 'ACTIVE'}
              // @ts-ignore
              color={'white'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openBuyPlayerShareModal(data.player.id, data.id);
              }}
            >
              {data.status === 'ACTIVE'
                ? t('EVENTS__playerShareButton')
                : t('EVENTS__playerFinishedButton')}
            </Button>
          </div>
        </ShareButtonWrapper>
      </Content>
    </Wrapper>
  );
};

type EventData = Pick<CommonEventDataFragment, 'location'>;

type TournamentData = Omit<
  TournamentPlayerShareCardsDataFragment,
  '__typename' | 'id' | 'slug' | 'venue' | 'player_shares'
> & {
  tournamentSlug: TournamentPlayerShareCardsDataFragment['slug'];
  venueSlug: TournamentPlayerShareCardsDataFragment['venue']['slug'];
};

type PlayerShareData =
  TournamentPlayerShareCardsDataFragment['player_shares'][number];

type Data = EventData & TournamentData & PlayerShareData;

const ShareButtonWrapper = styled('div')`
  margin-top: 8px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: -8px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: ${({ theme }) => theme.getColor('deepCove')};

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding: 8px 16px 16px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const Wrapper = styled(Paper)`
  position: relative;
  ${({ theme }) => theme.breakpoints.up('md')} {
    will-change: transform;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.04) translateY(-16px);

      ${ShareButtonWrapper} {
        max-height: 500px;
      }
    }
  }
`;

const Avatar = styled(Link)`
  display: block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  aspect-ratio: ${296 / 168};
  position: relative;
`;

const AvatarImg = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarName = styled(Text)`
  position: absolute;
  bottom: 14px;
  left: 16px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.2rem;
  }
`;

const Content = styled('div')`
  padding: 16px;
`;

const ContentName = styled(Text)``;
const ContentLocation = styled(Text)``;
const ContentDate = styled(Text)``;

const InfoBlocksList = styled('ul')`
  ${resetListStyles};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  margin-top: 12px;
`;

const ContentProgress = styled(ShareProgress)`
  margin-top: 12px;
`;

export { PlayerShareCard };
