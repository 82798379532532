import { resetListStyles } from '@/styles';
import { styled } from '@mui/material/styles';

export const PlayerShareCardsList = styled('ul')`
  ${resetListStyles};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 56px 32px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: auto;
  }
`;
